import type { AccessType } from "keycloak-admin-client/lib/defs/whoAmIRepresentation";
import { PropsWithChildren, useEffect, useState } from "react";
import { useRealm } from "../../context/realm-context/RealmContext";
import { useWhoAmI } from "../../context/whoami/WhoAmI";
import { createNamedContext, useRequiredContext } from "ui-shared";
import { adminClient } from "../../admin-client";

type AccessContextProps = {
  hasAccess: (...types: AccessType[]) => boolean;
  hasSomeAccess: (...types: AccessType[]) => boolean;
  // --- DA email tab permisssions --------------------------------------------
  hasMasterAccess: (...types: AccessType[]) => boolean;
  hasSomeMasterAccess: (...types: AccessType[]) => boolean;
  // --- END DA email tab permisssions ----------------------------------------
};

export const AccessContext = createNamedContext<AccessContextProps | undefined>(
  "AccessContext",
  undefined,
);

export const useAccess = () => useRequiredContext(AccessContext);

export const AccessContextProvider = ({ children }: PropsWithChildren) => {
  const { whoAmI } = useWhoAmI();
  const { realm } = useRealm();
  const [access, setAccess] = useState<readonly AccessType[]>([]);
  // --- DA email tab permisssions --------------------------------------------
  const [masterAccess, setMasterAccess] = useState<readonly AccessType[]>([]);
  // --- END DA email tab permisssions ----------------------------------------

  useEffect(() => {
    if (whoAmI.getRealmAccess()[realm]) {
      setAccess(whoAmI.getRealmAccess()[realm]);
    }
    // --- DA email tab permisssions ------------------------------------------
    adminClient.whoAmI
      .find({ currentRealm: "master", realm: "master" })
      .then((meInMaster) => setMasterAccess(meInMaster.realm_access["master"]))
      .catch(() => {
        return;
      });
    // --- END DA email tab permisssions --------------------------------------
  }, [whoAmI, realm]);

  const hasAccess = (...types: AccessType[]): boolean => {
    return types.every(
      (type) =>
        type === "anyone" ||
        (typeof type === "function" &&
          type({ hasAll: hasAccess, hasAny: hasSomeAccess })) ||
        access.includes(type),
    );
  };

  const hasSomeAccess = (...types: AccessType[]): boolean => {
    return types.some(
      (type) =>
        type === "anyone" ||
        (typeof type === "function" &&
          type({ hasAll: hasAccess, hasAny: hasSomeAccess })) ||
        access.includes(type),
    );
  };

  // --- DA email tab permisssions --------------------------------------------
  const hasMasterAccess = (...types: AccessType[]) => {
    return types.every((type) => masterAccess.includes(type));
  };

  const hasSomeMasterAccess = (...types: AccessType[]) => {
    return types.some((type) => masterAccess.includes(type));
  };
  // --- END DA email tab permisssions ----------------------------------------

  return (
    <AccessContext.Provider
      value={{ hasAccess, hasSomeAccess, hasMasterAccess, hasSomeMasterAccess }}
    >
      {children}
    </AccessContext.Provider>
  );
};
